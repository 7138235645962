import {createGlobalStyle} from 'styled-components'

export const StyledGridGlobalStyles = createGlobalStyle`
/* Container */
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Row */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

/* Base column class */
[class^="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Column widths */
.col-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
.col-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
.col-3 { flex: 0 0 25%; max-width: 25%; }
.col-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
.col-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
.col-6 { flex: 0 0 50%; max-width: 50%; }
.col-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
.col-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
.col-9 { flex: 0 0 75%; max-width: 75%; }
.col-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
.col-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
.col-12 { flex: 0 0 100%; max-width: 100%; }

/* Responsive breakpoints */
@media (min-width: 576px) {
  .container { max-width: 540px; }
  .col-sm-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-sm-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-sm-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-sm-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-sm-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 768px) {
  .container { max-width: 720px; }
  .col-md-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-md-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-md-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-md-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-md-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 992px) {
  .container { max-width: 960px; }
  .col-lg-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-lg-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-lg-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-lg-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-lg-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
}

@media (min-width: 1200px) {
  .container { max-width: 1140px; }
  .col-xl-1 { flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-xl-2 { flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-xl-5 { flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-xl-8 { flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-xl-11 { flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
}
`
